body {
    --terminal-black: #4f5666;
    --terminal-red: #f85757;
    --terminal-green: #50d750;
    --terminal-yellow: #f9e131;
    --terminal-blue: #3396ff;
    --terminal-magenta: #de73ff;
    --terminal-cyan: #4cdee0;
    --terminal-white: #e6e6e6;
    --terminal-bright-black: #4f5666;
    --terminal-bright-red: #f85757;
    --terminal-bright-green: #50d750;
    --terminal-bright-yellow: #f9e131;
    --terminal-bright-blue: #3396ff;
    --terminal-bright-magenta: #de73ff;
    --terminal-bright-cyan: #4cdee0;
    --terminal-bright-white: #e6e6e6;
}
