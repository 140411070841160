/* These colours from Cynthia Brewer's excellent page at
 * http://colorbrewer2.org/
 */
/* rainbow is 12 Set3 */
.rainbow-0 {
    background: rgba(141, 211, 199, 0.35);
}

.rainbow-1 {
    background: rgba(255, 255, 179, 0.35);
}

.rainbow-2 {
    background: rgba(190, 186, 218, 0.35);
}

.rainbow-3 {
    background: rgba(251, 128, 114, 0.35);
}

.rainbow-4 {
    background: rgba(128, 177, 211, 0.35);
}

.rainbow-5 {
    background: rgba(253, 180, 98, 0.35);
}

.rainbow-6 {
    background: rgba(179, 222, 105, 0.35);
}

.rainbow-7 {
    background: rgba(252, 205, 229, 0.35);
}

.rainbow-8 {
    background: rgba(217, 217, 217, 0.35);
}

.rainbow-9 {
    background: rgba(188, 128, 189, 0.35);
}

.rainbow-10 {
    background: rgba(204, 234, 197, 0.35);
}

.rainbow-11 {
    background: rgba(255, 237, 111, 0.35);
}

/* rainbow2 is 12 Paired */
.rainbow2-0 {
    background: rgba(166, 206, 227, 0.35);
}

.rainbow2-1 {
    background: rgba(31, 120, 180, 0.35);
}

.rainbow2-2 {
    background: rgba(178, 223, 138, 0.35);
}

.rainbow2-3 {
    background: rgba(51, 160, 44, 0.35);
}

.rainbow2-4 {
    background: rgba(251, 154, 153, 0.35);
}

.rainbow2-5 {
    background: rgba(227, 26, 28, 0.35);
}

.rainbow2-6 {
    background: rgba(253, 191, 111, 0.35);
}

.rainbow2-7 {
    background: rgba(255, 127, 0, 0.35);
}

.rainbow2-8 {
    background: rgba(202, 178, 214, 0.35);
}

.rainbow2-9 {
    background: rgba(106, 61, 154, 0.35);
}

.rainbow2-10 {
    background: rgba(255, 255, 153, 0.35);
}

.rainbow2-11 {
    background: rgba(177, 89, 40, 0.35);
}

/* earth is 'BrBG' */
.earth-0 {
    background: rgba(140, 81, 10, 0.35);
}

.earth-1 {
    background: rgba(191, 129, 45, 0.35);
}

.earth-2 {
    background: rgba(223, 194, 125, 0.35);
}

.earth-3 {
    background: rgba(246, 232, 195, 0.35);
}

.earth-4 {
    background: rgba(245, 245, 245, 0.35);
}

.earth-5 {
    background: rgba(199, 234, 229, 0.35);
}

.earth-6 {
    background: rgba(128, 205, 193, 0.35);
}

.earth-7 {
    background: rgba(53, 151, 143, 0.35);
}

.earth-8 {
    background: rgba(1, 102, 94, 0.35);
}

/* Greens and blues is Paired */
.green-blue-0 {
    background: rgba(166, 206, 227, 0.35);
}

.green-blue-1 {
    background: rgba(31, 120, 180, 0.35);
}

.green-blue-2 {
    background: rgba(178, 223, 138, 0.35);
}

.green-blue-3 {
    background: rgba(51, 160, 44, 0.35);
}

/* Gray shades */
.gray-shade-0 {
    background: rgba(190, 190, 190, 0.15);
}

.gray-shade-1 {
    background: rgba(150, 150, 150, 0.15);
}

.gray-shade-2 {
    background: rgba(110, 110, 110, 0.15);
}

.gray-shade-3 {
    background: rgba(70, 70, 70, 0.15);
}

/*rainbow dark*/
.rainbow-dark-0 {
    background: rgba(40, 83, 76, 0.35);
}

.rainbow-dark-1 {
    background: rgba(102, 102, 29, 0.35);
}

.rainbow-dark-2 {
    background: rgba(38, 28, 104, 0.35);
}

.rainbow-dark-3 {
    background: rgba(75, 18, 11, 0.35);
}

.rainbow-dark-4 {
    background: rgba(30, 71, 100, 0.35);
}

.rainbow-dark-5 {
    background: rgba(110, 65, 13, 0.35);
}

.rainbow-dark-6 {
    background: rgba(48, 75, 3, 0.35);
}

.rainbow-dark-7 {
    background: rgba(109, 6, 59, 0.35);
}

.rainbow-dark-8 {
    background: rgba(66, 47, 47, 0.35);
}

.rainbow-dark-9 {
    background: rgba(53, 8, 54, 0.35);
}

.rainbow-dark-10 {
    background: rgba(24, 97, 8, 0.35);
}

.rainbow-dark-11 {
    background: rgba(105, 94, 7, 0.35);
}

/*soft rainbow dark*/
.soft-rainbow-dark-0 {
    background: rgba(79, 140, 135, 0.15);
}

.soft-rainbow-dark-1 {
    background: rgba(160, 160, 49, 0.15);
}

.soft-rainbow-dark-2 {
    background: rgba(80, 80, 183, 0.15);
}

.soft-rainbow-dark-3 {
    background: rgba(162, 50, 24, 0.15);
}

.soft-rainbow-dark-4 {
    background: rgba(51, 122, 173, 0.15);
}

.soft-rainbow-dark-5 {
    background: rgba(186, 136, 23, 0.15);
}

.soft-rainbow-dark-6 {
    background: rgba(125, 178, 70, 0.15);
}

.soft-rainbow-dark-7 {
    background: rgba(165, 60, 109, 0.15);
}

.soft-rainbow-dark-8 {
    background: rgba(125, 88, 88, 0.15);
}

.soft-rainbow-dark-9 {
    background: rgba(120, 42, 151, 0.15);
}

.soft-rainbow-dark-10 {
    background: rgba(47, 120, 35, 0.15);
}

/* Gray shades */
/*@for $i from 0 through 4 {
    .pink- hashtag {$i} {
        background: darken(#fae1fa, (($i + 1) * 2) / 100 * 100);
    }
}*/
.pink-0 {
    background: adjust-color(#f9d8f9, $alpha: -0.35);
}

.pink-1 {
    background: adjust-color(#f7d0f7, $alpha: -0.35);
}

.pink-2 {
    background: adjust-color(#f6c7f6, $alpha: -0.35);
}

.pink-3 {
    background: adjust-color(#f4bef4, $alpha: -0.35);
}

.pink-4 {
    background: adjust-color(#f3b5f3, $alpha: -0.35);
}
